import React, { useState } from 'react'
import styled from 'styled-components'
import type { SubmitHandler } from 'react-hook-form'
import Loading from 'components/section/form/components/loading'
import HeadSection from 'components/section/headSection'
import { ContactFormSection } from 'components/section/form/contactFormSection'
import type { FormInputs } from 'types/form'
import encode from 'utils/encode'
import LeadSection from 'components/section/form/leadSection'
import axios from 'axios'
import ThanksSection from 'components/section/form/thanksSection'

export interface ContactTemplateProps {
  readonly headingText: string
  readonly userAgent: string
}

type ContactTemplatePropsClassName = ContactTemplateProps & {
  readonly className: string
}

function Component({ className, headingText, userAgent }: ContactTemplatePropsClassName) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isContactData, setIsContactData] = useState<boolean>(false)

  // エラーがなければ送信
  const onSubmit: SubmitHandler<FormInputs> = data => {
    setIsLoading(true)

    axios
      .post(
        '/',
        encode({
          'form-name': 'contact',
          formType: 'contact',
          userAgent: userAgent,
          ...data,
        }),
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
      )
      .then(response => {
        if (typeof window !== 'undefined' && (window as any).dataLayer) {
          ;(window as any).dataLayer.push({ event: 'send-form-contact' })
        }
        setIsContactData(true)
        setIsLoading(false)
      })
      .catch(error => {
        // 失敗時の処理
        if (error) {
          setIsLoading(false)
        }
      })

    window.scroll({ top: 0 })
  }

  return (
    <div className={className}>
      <HeadSection
        pageHeadProps={{
          headingText: headingText,
          headingLevel: 'h1',
        }}
      />
      {!isContactData ? (
        <>
          <LeadSection
            leadElement={
              <>
                必要事項をご入力の上、「お問い合わせする」ボタンを押してください。
                {/* <br />
                <span className="u-bold">※年末年始休業のため、12/28～1/3のお問い合わせは1/4以降にご連絡します。</span> */}
              </>
            }
            imageType=""
          />
          <ContactFormSection onSubmit={onSubmit} userAgent={userAgent} />
        </>
      ) : (
        <ThanksSection
          headElement={<>送信完了</>}
          bodyElement={
            <>
              <p>
                Fit NET STUDYへのお問い合わせを受け付けました。
                <br />
                3営業日以内に弊社よりメール・もしくはお電話にてご連絡差し上げます。
              </p>
              <p>【弊社からの連絡がない場合】</p>
              <p>お手数ですが、下記フリーコールまでお問い合わせください。</p>
              <p>
                0120-937-380
                <br />
                受付時間 9:00~21:00（日・祝を除く）
              </p>
            </>
          }
        />
      )}

      {isLoading && <Loading />}
    </div>
  )
}

const StyledComponent = styled(Component)<ContactTemplatePropsClassName>``

export const ContactTemplate: (props: ContactTemplateProps) => JSX.Element = StyledComponent

export default ContactTemplate
