import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import Rellax from 'rellax'
import {
  rem,
  innerWidth,
  breakpoints,
  innerPadding,
  innerDummyPadding,
} from 'variables/_index'

export interface LeadSectionProps {
  readonly leadElement: JSX.Element
  readonly imageType?: 'note' | 'pen' | ''
}

type LeadSectionPropsClassName = LeadSectionProps & {
  readonly className: string
}

function Component({ className, leadElement, imageType }: LeadSectionPropsClassName) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      new Rellax(ref.current, {
        speed: 1.5, // -10 ~ 10
        center: false,
      })
    }
  }, [])

  return (
    <div className={className}>
      <div className="e-inner">
        <div className="e-text">{leadElement}</div>
        <div ref={ref} className={classNames('e-image', `is-${imageType}`)}>
          {imageType === 'note' && (
            <StaticImage
              src="../../../assets/images/illustration/illustration3-2.png"
              alt=""
            />
          )}
          {imageType === 'pen' && (
            <StaticImage
              src="../../../assets/images/illustration/illustration2-2.png"
              alt=""
            />
          )}
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<LeadSectionPropsClassName>`
  padding-top: ${rem(105 / 2)};
  padding-bottom: ${rem(105 / 2)};

  @media ${breakpoints.md} {
    padding-top: ${rem(105 / 2)};
    padding-bottom: ${rem(105 / 2)};
  }

  > .e-inner {
    position: relative;
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 2;

    @media ${breakpoints.md} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-text {
      font-size: ${rem(32 / 2)};
      line-height: 1.56;
      position: relative;
      z-index: 2;
    }

    > .e-image {
      position: absolute;
      right: ${rem(0)};
      bottom: ${rem(0)};
      z-index: 1;

      @media ${breakpoints.md} {
        right: ${rem(innerPadding.md + innerDummyPadding.md)};
      }

      &.is-note {
        width: ${rem(82.11 / 2)};
        height: ${rem(130 / 2)};
      }

      &.is-pen {
        width: ${rem(106.34 / 2)};
        height: ${rem(60 / 2)};
      }
    }
  }
`

export const LeadSection: (props: LeadSectionProps) => JSX.Element = StyledComponent

export default LeadSection
