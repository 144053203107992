import React, { useState, useEffect } from 'react'
import Seo from 'components/seo'
import Layout from 'layout/base'
import type { TypeBreadcrumb } from 'types/breadcrumb'
import { siteStructure } from 'utils/site'
import globalTitle from 'utils/globalTitle'
import ContactTemplate from 'templates/contactTemplate'

interface ContactPageProps {
  pageContext: TypeBreadcrumb
  location: Location
}

const ContactPage = ({ pageContext, location }: ContactPageProps): JSX.Element => {
  const [userAgent, setUserAgent] = useState<string>('')

  const {
    breadcrumb: { crumbs },
  } = pageContext

  // TODO : 全ページで作っているので共通化したい
  const breadcrumbItems = crumbs
    .filter(crumb => {
      return crumb.crumbLabel !== 'form'
    })
    .map(crumb => {
      return crumb.crumbLabel in siteStructure
        ? {
            ...crumb,
            ...{ crumbLabel: siteStructure[crumb.crumbLabel]?.name },
          }
        : { ...crumb }
    })

  useEffect(() => {
    setUserAgent(window?.navigator?.userAgent ? window?.navigator?.userAgent : '取得失敗')
  }, [])

  return (
    <Layout pageType="page" isDisplayCTASection={false} breadcrumbItems={breadcrumbItems} isDisplayFixedFooter={false}>
      <Seo
        title={`お問い合わせフォーム${globalTitle(location.pathname)}`}
        description="フィットネットスタディに関してご不明な点やご相談はこちらからお問い合わせください。"
        path={location.pathname}
      />
      <ContactTemplate headingText="お問い合わせフォーム" userAgent={userAgent} />
    </Layout>
  )
}

export default ContactPage
